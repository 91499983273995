@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
.carrier-page {
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.carrier-container {
  width: 100%;
  height: 250px;
}
.carrier-box {
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 250px;

  display: flex;
}

.carrier-box > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 80px;
}

.carrier-box > div > p {
  font-size: 20px;
  color: #fff;
}

.carrier-box > div > p > span {
  color: coral;
}

.carrier-box > div > h1 {
  font-family: "playfair display";
  font-size: 50px;
  text-align: left;
  margin-top: -5px;
  color: #fff;
}

.carrier-page-content {
  padding-left: 200px;
  padding-right: 200px;
}

.carrier-form {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.carrier-form-about {
  font-size: 25px;
  flex: 1;
}
.carrier-form-header-maindiv {
  padding-top: 30px;
}

.carrier-form-header {
  color: #212158;

  font-size: 50px;
  margin-top: -10px;
  font-weight: 600;
  font-family: "Playfair Display";
}
.carrier-form-header2 {
  color: #fea12a;
  margin-top: -35px;
  font-size: 50px;
  font-weight: 600;
  font-family: "Playfair Display";
  text-transform: uppercase;
}
.carrier-form-image img {
  width: 100%; /* Set the width to 100% of the parent container */
  height: auto; /* Maintain the aspect ratio */
  max-width: 500px; /* Optionally, set a maximum width */
}
.carrier-form-about {
  font-family: "Roboto Slab", serif;
  font-size: 19px;
}

.carrier-form-about h2 {
  font-size: 22px;
  word-spacing: 5px;
  text-transform: uppercase;
  color: #212158;
  margin-top: -30px;
}

.button-carrier {
  display: inline-block;
  margin: 10px 0 0 30px;
  background-color: #fea12a;
  color: #fff;
  padding: 6px 6px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #fea12a;
  width: 140px;
  height: 40px;
  margin-right: 30px;
}
.button-carrier:hover {
  background-color: #fff;
  transition: background-color 0.3s ease;
  color: #fea12a;
}

.input-field {
  flex: 1;
}

.input-header {
  font-family: "Roboto Slab", serif;
  font-size: 40px;
  color: #ef5f34;
}

.input-subheader {
  font-family: "Roboto Slab", serif;
  font-size: 30px;
  color: #212158;
  padding-bottom: 40px;
  margin-top: -20px;
}
.input-field-openings form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
}

.input-field-openings input,
.input-field-openings textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e2e2e2;
  box-sizing: border-box;
  margin-top: 5px;
}

.input-field-openings textarea {
  height: 100px; /* Adjust the height as needed */
}

.file-upload > input {
  margin-left: -70px;
  margin-top: 20px;
}

.but-opening {
  margin-top: 10px;
  border: none;
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 10px;
  width: 15%;
  background-color: #fea12a;
}

.span-opening {
  color: white;
  font-size: 16px;
}
.Cg-test {
  padding-top: 60px;
  padding-bottom: 60px;
}

.Cg-test .Cgtest-header {
  padding-bottom: 40px;
}

.Cg-test .Cgtest-header .Cgtest-small-text {
  font-size: 40px;
  font-family: "Playfair Display";
  color: #ef5f34;
  font-weight: 600;
}

.Cg-test .Cgtest-header .Cgtest-title {
  font-size: 19px;
  font-family: "Roboto Slab", serif;
  color: #212158;
  margin-top: 30px;
}

.team-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Style for the image container */
.team-box1 .img-team-div1 {
  width: 150px; /* Adjust the width as needed */
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.team-box1 .img-team-div1 img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

/* Style for the team name */
.team-name1 {
  font-size: 28px;
  padding-top: 10px;
  color: #212158;
  font-weight: 500;
  font-family: "Playfair Display", serif;
}

/* Style for the team expertise */
.team-expertise1 {
  font-size: 18px;
  color: #ef5f34;
  padding-top: 1px;
  font-family: "Playfair Display", serif;
  margin-top: -20px;
}

/* Style for the team bio */
.team-bio1 {
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-top: 10px;
  color: #4f555a;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  padding-left: 60px;
  padding-right: 40px;
  margin-top: -20px;
}

.info-test {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 500px;
}

.perks-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo-container {
  background-color: #212158;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; /* Adjust this margin as needed */
}

.logo-container svg {
  color: white; /* Adjust the color as needed */
}

.perks-title {
  font-size: 40px;
  font-family: "Playfair Display";
  color: #ef5f34;
  font-weight: 600;
}
.perks-subtitle {
  font-size: 19px;
  font-family: "Roboto Slab", serif;
  color: #212158;
  padding-bottom: 50px;
}

.perks-cuntant {
  display: flex;

  justify-content: center;
  align-items: center;
  column-gap: 70px;
}
.perks-content {
  width: 200px;
  height: 300px;
}
.perks-text {
  font-family: "Roboto Slab", serif;
  font-size: 15px;
  padding-bottom: 60px;
  font-weight: 400;

  width: 230px;
  height: 100px;
}

.openings-section {
  padding-bottom: 50px;
}
.openings-title {
  font-size: 40px;
  font-family: "Playfair Display";
  color: #ef5f34;
  font-weight: 600;
  padding-bottom: 40px;
}

.openings-colomn {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #ccc;
}

.first-part {
  display: flex;
}

.opening-img-div {
  padding-top: 45px;
}
.openings-texts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 14px;
}
.openings-name {
  font-family: "Roboto Slab", serif;
  font-size: 23px;
  padding-top: 25px;
  color: #212158;
}

.openings-deadline {
  font-size: 17px;
  font-family: "Playfair Display";
  font-weight: 600;
  margin-top: -15px;
  color: #ef5f34;
}
.last-part {
  display: flex;
  justify-content: center;
  align-items: center;
}
.joinnow {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
  padding-right: 10px;
  padding-top: 6px;
  cursor: pointer;
  color: #212158;
}

@media screen and (max-width: 1140px) {
  .carrier-page .carrier-page-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .carrier-form-header {
    color: #7e7e7e;

    font-size: 40px;
    font-weight: 600;
    font-family: "Playfair Display";
  }

  .carrier-form-header2 {
    color: #fea12a;
    margin-top: -20px;
    font-size: 50px;
    font-weight: 600;
    font-family: "Playfair Display";
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .carrier-form-about {
    font-family: "Roboto Slab", serif;
    padding-top: 5px;
  }

  .carrier-form-about h2 {
    font-size: 16px;
    word-spacing: 1px;
    text-transform: uppercase;
    line-height: 20px;
  }
  .team-bio1 {
    font-size: 14px;
    color: #555;
    text-align: center;
    margin-top: 10px;
    color: #4f555a;

    font-family: "Mulish", sans-serif;
    font-weight: 700;
    padding-left: 0px;
    padding-right: 0px;
  }

  .perks-title {
    font-size: 40px;
    font-family: "Playfair Display";
    color: #ef5f34;
    font-weight: 600;
  }
  .perks-subtitle {
    font-size: 19px;
    font-family: "Roboto Slab", serif;
    color: #212158;
    padding-bottom: 50px;
  }

  .perks-cuntant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 70px;
  }
  .perks-content {
    width: 300px;
    height: 280px;
  }
  .perks-text {
    font-family: "Roboto Slab", serif;
    font-size: 15px;
    padding-bottom: 60px;
    font-weight: 400;
    width: 500px;
  }
}

.announcement-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.announcement-card {
  display: flex;
  width: 65%;
  margin: 26px;
  /* box-shadow: 2px 2px 6px #000000, -2px -2px 6px #000000; */
  cursor: pointer;
  text-align: left;
  gap: 20px;
}
.announcement-card1 {
  width: 45%;
  height: 400px;
  overflow: hidden;
}

.announcement-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ease 0.5s;
}

.announcement-image:hover {
  transform: scale(1.2);
}

.announcement-card2 {
  width: 55%;
  padding: 46px;
}

.announcement-date {
  color: #ef5f34;
  font-weight: 300;
}
.announcement-title {
  margin-top: -15px;
  font-size: 24px;
  color: #212158;
}

.learn-more-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.learn-more-btn {
  width: 12rem;
  height: auto;
}

.learn-more-btn .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #212158;
  border-radius: 1.625rem;
}

.learn-more-btn .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.learn-more-btn .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.learn-more-btn .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.learn-more-btn .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #212158;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.learn-more-btn:hover .circle {
  width: 100%;
}

.learn-more-btn:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.learn-more-btn:hover .button-text {
  color: #fff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: start;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  overflow: auto;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-event {
  margin-left: -60%;
  font-size: 21px;
  color: #fa930e;
}

.close-btn {
  padding: 10px 20px;
  background: #ff5722;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .carrier-page {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .carrier-container {
    width: 100%;
    height: 250px;
    border-radius: 0px;
  }
  .carrier-box {
    background: rgba(0, 0, 0, 0.55);
    width: 100%;
    height: 250px;
    border-radius: 0px;
    display: flex;
  }

  .carrier-box > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 80px;
  }

  .carrier-box > div > p {
    font-size: 20px;
    color: #fff;
  }

  .carrier-box > div > p > span {
    color: coral;
  }

  .carrier-box > div > h1 {
    font-family: "playfair display";
    font-size: 33px;
    text-align: left;
    margin-top: -5px;
    color: #fff;
  }

  .carrier-page-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .perks-title {
    font-size: 40px;
    font-family: "Playfair Display";
    color: #ef5f34;
    font-weight: 600;
  }
  .perks-subtitle {
    font-size: 19px;
    font-family: "Roboto Slab", serif;
    color: #212158;
    padding-bottom: 50px;
  }

  .perks-cuntant {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }
  .perks-content {
    width: 200px;
    height: 300px;
  }
  .perks-text {
    font-family: "Roboto Slab", serif;
    font-size: 15px;
    padding-bottom: 60px;
    font-weight: 400;

    width: 230px;
    height: 100px;
  }

  .openings-section {
    padding-bottom: 50px;
  }
  .openings-title {
    font-size: 40px;
    font-family: "Playfair Display";
    color: #ef5f34;
    font-weight: 600;
    padding-bottom: 40px;
  }

  .openings-colomn {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #ccc;
  }

  .first-part {
    display: flex;
  }

  .opening-img-div {
    padding-top: 50px;
  }
  .openings-texts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0px;
  }
  .openings-name {
    font-family: "Roboto Slab", serif;
    font-size: 20px;
    padding-top: 25px;
    padding-left: 10px;
    width: 200px;
    text-align: left;
    color: #212158;
  }

  .openings-deadline {
    font-size: 17px;
    font-family: "Playfair Display";
    font-weight: 600;
    margin-top: -10px;
    padding-left: 10px;
    color: #ef5f34;
  }
  .last-part {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .joinnow {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;

    font-weight: 600;
    letter-spacing: -1px;
    padding-right: 10px;
    padding-top: 6px;
    cursor: pointer;
    color: #212158;
  }

  .carrier-form {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
  }

  .carrier-form-about {
    font-size: 25px;
    flex: 1;
  }
  .carrier-form-header-maindiv {
    padding-top: 30px;
  }

  .carrier-form-header {
    color: #212158;

    font-size: 38px;
    margin-top: -10px;
    font-weight: 600;
    font-family: "Playfair Display";
    line-height: 33px;
  }
  .carrier-form-header2 {
    color: #fea12a;
    margin-top: -20px;
    font-size: 43px;
    font-weight: 600;
    font-family: "Playfair Display";
    text-transform: uppercase;
  }
  .carrier-form-image img {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
  .carrier-form-about {
    font-family: "Roboto Slab", serif;
    font-size: 19px;
  }

  .carrier-form-about h2 {
    font-size: 18px;
    line-height: 20px;
    word-spacing: 5px;
    text-transform: uppercase;
    color: #212158;
    margin-top: -30px;
  }

  .button-carrier {
    margin-top: -5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 12px;
    background-color: #fa930e;
    border: none;
    cursor: pointer;
  }

  .button-carrier span {
    color: white;
    font-size: 24px;
  }
  .input-field {
    flex: 1;
  }

  .input-header {
    font-family: "Roboto Slab", serif;
    font-size: 40px;
    color: #ef5f34;
  }

  .input-subheader {
    font-family: "Roboto Slab", serif;
    font-size: 30px;
    color: #212158;
    padding-bottom: 40px;
    margin-top: -20px;
  }
  .input-field-openings form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
  }

  .input-field-openings input,
  .input-field-openings textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #e2e2e2;
    box-sizing: border-box;
    margin-top: 5px;
  }

  .input-field-openings textarea {
    height: 100px; /* Adjust the height as needed */
  }

  .file-upload > input {
    margin-left: -70px;
    margin-top: 20px;
  }

  .but-opening {
    margin-top: 10px;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 40%;
    background-color: #fea12a;
  }

  .span-opening {
    color: white;
    font-size: 16px;
  }
  .announcement-card {
    display: flex;
    flex-direction: column;
  }
  .announcement-card1 {
    width: 100%;
  }
  .announcement-card2 {
    width: 100%;
    padding: 15px;
  }
}
.modal-content2 {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.image-gallery-title {
  margin-bottom: 10px;
  margin-left: -66%;
  font-size: 21px;
  color: #fa930e;
}

.modal-image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  justify-items: center;
  margin-top: 10px;
}

.image-container {
  text-align: center;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.image-caption {
  font-size: 14px;
  margin-top: 5px;
  color: #666;
}

.close-btn {
  margin-top: 20px;
  background-color: #ff5c5c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.close-btn:hover {
  background-color: #ff3333;
}
