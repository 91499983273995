.footer {
  margin-top: 100px;
  background-color: #1e1e1e;
  color: #fff;
  padding: 50px 200px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
}

.footer-left {
  text-align: left;
}

.footer-logo h2 {
  font-size: 24px;
  color: #fea12a;
}
.footer-logo a {
  text-decoration: none;
}

.company-info {
  margin-top: 40px;
}

.social {
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.social p:hover {
  color: #fea12a;
}

.footer-midA h1,
.footer-midB h1,
.footer-right h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.footer-midA p a,
.footer-midB p a,
.footer-right p a {
  text-decoration: none;
  color: #787878;
  transition: color 0.3s ease;
}

.footer-midA p a:hover,
.footer-midB p a:hover,
.footer-right p a:hover {
  color: #fea12a;
}

.footer-copyright {
  margin-top: 60px;
  opacity: 0.8;
  font-size: 14px;
}

.footer-link {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.footer-link:hover {
  transform: scale(1.08);
}

.vertical-line {
  border-left: 2px solid rgb(255, 255, 255);
  height: 20px;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 768px) {
  .footer {
    margin-top: 100px;
    background-color: #1e1e1e;
    color: #fff;
    padding: 20px;
    padding-bottom: 10px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
  }

  .footer-left {
    text-align: left;
  }

  .footer-logo h2 {
    font-size: 24px;
    color: #fea12a;
  }
  .footer-logo a {
    text-decoration: none;
  }

  .company-info {
    margin-top: 40px;
  }

  .social {
    display: flex;
    gap: 20px;
    cursor: pointer;
  }

  .social p:hover {
    color: #fea12a;
  }

  .footer-midA h1,
  .footer-midB h1,
  .footer-right h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .footer-midA p a,
  .footer-midB p a,
  .footer-right p a {
    text-decoration: none;
    color: #787878;
    transition: color 0.3s ease;
  }

  .footer-midA p a:hover,
  .footer-midB p a:hover,
  .footer-right p a:hover {
    color: #fea12a;
  }

  .footer-copyright {
    margin-top: 60px;
    opacity: 0.8;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    background-color: #1e1e1e;
    color: #fff;
    padding-left: 0px;
    padding-right: 0px;

    padding-bottom: 10px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .footer-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 90px;
    margin-top: 60px;
  }

  .footer-left {
    text-align: left;
  }

  .footer-logo h2 {
    font-size: 24px;
    color: #fea12a;
  }
  .footer-logo a {
    text-decoration: none;
  }

  .company-info {
    margin-top: 40px;
  }

  .social {
    display: flex;
    gap: 20px;
    cursor: pointer;
  }

  .social p:hover {
    color: #fea12a;
  }

  .footer-midA h1,
  .footer-midB h1,
  .footer-right h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .footer-midA p a,
  .footer-midB p a,
  .footer-right p a {
    text-decoration: none;
    color: #787878;
    transition: color 0.3s ease;
  }

  .footer-midA p a:hover,
  .footer-midB p a:hover,
  .footer-right p a:hover {
    color: #fea12a;
  }

  .footer-copyright {
    margin-top: 60px;
    opacity: 0.8;
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.example-2 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -20% !important;
  top: 1px !important;
  row-gap: 1.5rem;
  z-index: 10;
  list-style-type: none;
}
.example-2 .icon-content {
  margin: 0 10px;
  position: relative;
}

.example-2 .icon-content a {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  color: #4d4d4d;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover {
  box-shadow: 3px 2px 45px 0px rgb(0 0 0 / 12%);
}
.example-2 .icon-content a svg {
  position: relative;
  z-index: 1;
  width: 30px;
  height: 30px;
}
.example-2 .icon-content a:hover {
  color: white;
}
.example-2 .icon-content a .filled {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000;
  transition: all 0.3s ease-in-out;
}
.example-2 .icon-content a:hover .filled {
  height: 100%;
}

.example-2 .icon-content a[data-social="facebook"] .filled {
  background-color: #0274b3;
}

.example-2 .icon-content a[data-social="github"] .filled {
  background-color: #24262a;
}
.example-2 .icon-content a[data-social="instagram"] .filled {
  background: linear-gradient(
    45deg,
    #405de6,
    #5b51db,
    #b33ab4,
    #c135b4,
    #e1306c,
    #fd1f1f
  );
}
.example-2 .icon-content a[data-social="youtube"] .filled {
  background-color: #ff0000;
}
