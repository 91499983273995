/* FullScreenCarousel.css */
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap");
.full-screen-carousel {
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  animation-duration: 1.5s;
}

.hero-container {
  width: 100%;
  height: 700px;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0.842) 45%,
    rgba(255, 255, 255, 0) 100%
  );
}
.hero-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin-top: 80px;
  margin-left: 200px;
}
.hero-title > h1 {
  margin-top: 200px;
  font-family: "Teko", sans-serif;
  font-size: 55px;
  font-weight: 500;
  margin-bottom: -25px;
}
.hero-title > h1 > mark {
  background-color: transparent;
  color: #fff;
}

.hero-para {
  width: 70%;
}
.hero-para > p {
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 30px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.hero-cta > button {
  width: auto;
  height: 60px;
  padding: 20px;
  border-style: none;
  background-color: #fea12a;
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease;
}

.carousel-slide.active {
  opacity: 1;
}
.arrow-symbol {
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin-left: 40px;
  top: 50%;
  width: 95%;
}

/* .carousel-list {
  position: absolute;
  margin-left: 200px;
  bottom: 20px;
  display: flex;
  column-gap: 20px;
  list-style-type: none;
  padding: 0;
} */

/* .carousel-list li {
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 5px 10px;
} */

/* .carousel-list li.active {
  color: #fea12a;
} */

/* .carousel-list li:hover {
  color: #fea12a;
} */

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .full-screen-carousel {
    height: 550px;
  }
  .hero-container {
    height: 550px;
  }
  .hero-content {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .hero-title > h1 {
    margin-top: 120px;
    font-family: "Teko", sans-serif;
    font-size: 40px;
    line-height: 35px;
    font-weight: 500;

    margin-bottom: -25px;
    padding-bottom: 20px;
    text-align: left;
  }
  .hero-title > h1 > mark {
    background-color: transparent;
    color: #fff;
    text-align: left;
    display: flex;
  }

  .hero-para {
    width: 99%;
  }
  .hero-para > p {
    font-size: 17px;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: left;
    padding-left: 0px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
  }
  .hero-cta > button {
    width: auto;
    height: 60px;
    padding: 20px;
    border-style: none;
    background-color: #fea12a;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease;
  }

  .carousel-slide.active {
    opacity: 1;
  }
  .arrow-symbol {
    display: flex;
    margin-left: 20px;
    margin-top: -30px;
  }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .full-screen-carousel {
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
    animation-duration: 1.5s;
  }

  .hero-container {
    width: 100%;
    height: 700px;

    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgb(0, 0, 0) 0%,
      rgba(0, 0, 0, 0.842) 45%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .hero-content {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    margin-top: 80px;
    margin-left: 100px;
  }
  .hero-title > h1 {
    margin-top: 200px;
    font-family: "Teko", sans-serif;
    font-size: 55px;
    font-weight: 500;
    line-height: 40px;
    margin-bottom: -10px;
  }
  .hero-title > h1 > mark {
    background-color: transparent;
    color: #fff;
  }

  .hero-para {
    width: 90%;
  }
  .hero-para > p {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 30px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
  }
  .hero-cta > button {
    width: auto;
    height: 60px;
    padding: 20px;
    border-style: none;
    background-color: #fea12a;
    color: #fff;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease;
  }

  .carousel-slide.active {
    opacity: 1;
  }
  .arrow-symbol {
    position: absolute;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    top: 50%;
    width: 95%;
  }
}
.wave-imgg {
  position: relative;
  z-index: 90;
  margin-top: -18.5rem;
  margin-bottom: 6rem;
  height: auto;
  width: 100%;
}
