@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600&display=swap");
.services {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  padding-left: 150px;
  padding-right: 150px;
}
.services-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.services-title {
  width: 80%;
  text-align: left;
}

.services-title > h1 {
  font-size: 50px;
  font-family: "Dm sans", sans-serif;
  font-size: 55px;
  font-weight: 600;
  padding-left: 50px;
}

.services-card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  background-color: #ebebeb;
}
.contact-us {
  text-decoration: none;
  color: #fea12a;
}

.services-card > img {
  width: 350px;
  height: 300px;
  object-fit: cover;
}

@media screen and (max-width: 1440px) {
  .services {
    padding-left: 30px;
    padding-right: 30px;
  }
  .services-title > h1 {
    font-size: 40px;
  }
  .card {
    justify-content: center;
    align-items: center;
  }
  .card-text {
    width: 285px;
  }
  .services-card > img {
    width: 350px;
  }
  .card {
    column-gap: 44px;
  }
}
@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .services {
    padding-left: 50px;
    padding-right: 50px;
  }
  .services-title > h1 {
    font-size: 40px;
  }

  .card {
    justify-content: center;
    align-items: center;
  }
  .card-text {
    width: 285px;
  }
  .services-card > img {
    width: 285px;
  }
  .card {
    column-gap: 34px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .services {
    padding-left: 20px;
    padding-right: 20px;
  }
  .services-content {
    display: flex;
    flex-direction: column;
  }
  .services-title {
    width: auto;
  }
  .services-title > h1 {
    font-size: 30px;
    text-align: center;
    padding-left: 0px;
  }
  .card {
    align-items: center;
    justify-content: center;
  }
}
.card2 {
  display: flex;
}
.hp-servicecard {
  width: 300px;
  height: 380px;
  background: rgb(255, 255, 255);
  border-radius: 32px;
  overflow: hidden;
  position: relative;
  /* box-shadow: 0px 70px 30px -50px rgba(96, 75, 74, 0.188); */
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #bebebe;
  margin: 120px auto;

  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.hp-servicecard.is-visible {
  opacity: 1;
  transform: translateY(-30px);
}

.hp-servicecard:hover .hp-serviceimage img {
  transform: scale(1.3);
  transition: transform 0.3s ease-in-out;
}

.hp-servicecard:hover .hp-servicecontent {
  transform: translateY(-35px);
  transition: transform 0.3s ease-in-out;
}

.hp-serviceimage {
  width: 100%;
  height: 60%;
  overflow: hidden;
}

.hp-serviceimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.hp-wave {
  position: absolute;
  bottom: 99.5%;
  left: 0;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 10;
}

.hp-servicecontent {
  height: 68%;
  padding: 1rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hp-servicetitle {
  margin-top: -5rem;
  font-family: recoleta-bold, sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: #d2d1d1;
  z-index: 100;
}

.hp-servicedescription {
  font-size: 0.9rem;
  color: #d2d1d1;
  z-index: 100;
}

.red {
  background-color: #15063a;
}

.green {
  background-color: #142f40;
}

.blue {
  /* background-color: #3d1b4b; */
  background-color: #0b263b;
}

.yellow {
  background-color: #4d3b00;
}

@media (max-width: 768px) {
  .service-heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .service-heading h1 {
    font-size: 26px;
  }

  .hp-servicecard {
    display: flex;
    flex-direction: row-reverse;
    width: 350px;
    height: 90px;
    border-radius: 15px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.188);
    margin: 4px auto !important;
    overflow: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  .hp-servicecard.is-visible {
    opacity: 1;
    transform: translateY(-30px);
  }

  .hp-servicecard:hover .hp-serviceimage img {
    transform: scale(1.3);
    transition: transform 0.3s ease-in-out;
  }

  .hp-servicecard:hover .hp-servicecontent {
    transform: translateY(0);
  }

  .hp-serviceimage {
    width: 35%;
    height: 100%;
    overflow: hidden;
  }
}

.cta {
  position: absolute;
  left: 75%;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #4e4e85;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ff9100;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ff9100;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #4e4e85;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}
