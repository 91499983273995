.services-container {
  width: 100%;
  height: 320px;
}
.services-box {
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 320px;
  border-radius: 0px;
  display: flex;
}
.services-box > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 80px;
}
.services-box > div > p {
  font-size: 20px;
  color: #fff;
}
.services-box > div > p > span {
  color: coral;
}
.services-box > div > h1 {
  color: #fff;
  font-family: "playfair display";
  font-size: 50px;
  text-align: center;
  margin-top: 25px;
}

/* Service content page */
.services-page-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding-left: 200px;
  padding-right: 200px;
}

.section-title {
  position: relative;
  text-align: center;
  margin: 50px 0;
}

.services-content-first {
  display: flex;
  column-gap: 40px;
}

.first-img {
  width: 450px;
  height: 500px;
  object-fit: cover;
  position: relative;
}
.first-img > img {
  width: 450px;
  height: 500px;
  object-fit: cover;
}
.first-img > h1 {
  position: absolute;
  top: 10px;
  left: 5%;
  font-size: 50px;
  font-weight: 700;
  line-height: 0px;
  color: #fff;
  font-family: "Londrina Outline", sans-serif;
}
.first-content {
  text-align: left;
}
.first-content > p {
  color: #787878;
}

/* Service content page 2 */
.services-content-second {
  display: flex;
  flex-direction: column;
}

.united-state {
  text-align: left;
  margin-top: 0px;
}
.united-state > h1 {
  color: #fea12a;
}

.united-services-list {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Fixed grid with responsive columns */
  grid-auto-rows: 1fr; /* Ensures rows are of equal height */
  gap: 20px; /* Space between cards */
  justify-content: center;
  align-items: stretch;
  row-gap: 210px;
}

.service-content {
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column; /* Stack content */
  justify-content: space-between; /* Distribute space evenly */
  align-items: center;
  height: 100%; /* Ensures card stretches to fill grid row height */
}

.service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-name {
  text-align: center;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.list-card {
  width: 350px;
  height: auto;
  position: relative;
}
.list-card > h1 {
  position: absolute;
  top: 10px;
  left: 5%;
  font-size: 30px;
  font-weight: 700;
  line-height: 0px;
}
.list-card > img {
  width: 350px;
  height: 300px;
  object-fit: cover;
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .services-page {
    padding-left: 0px;
    padding-right: 0px;
  }

  .services-container {
    margin-top: 0px;
  }
  .services-page-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .list-card {
    width: 320px;
  }
  .list-card > img {
    width: 320px;
  }
  .services-content-first {
    flex-direction: column;
    row-gap: 30px;
  }
  .first-img {
    width: 100%;
  }
  .first-img > img {
    width: 100%;
    height: 400px;
  }

  .united-services-list {
    display: flex;
    flex-direction: row;
  }

  .list-card {
    width: 300px;
  }
  .list-card > img {
    width: 300px;
  }

  .first-content {
    text-align: left;

    margin-top: -100px;
  }
  .services-title {
    line-height: 40px;
  }
  .first-content > p {
    color: #787878;
  }

  .other-services-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .other-services-list > .list-card {
    width: 320px;
  }
  .other-services-list > .list-card > img {
    width: 320px;
  }
}

@media only screen and (min-device-width: 300px) and (max-device-width: 768px) {
  .services-page {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .services-container {
    width: 100%;
    height: 250px;
    border-radius: 0px;
  }
  .services-box {
    background: rgba(0, 0, 0, 0.55);
    width: 100%;
    height: 250px;
    border-radius: 0px;
    display: flex;
  }
  .services-box > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 80px;
  }
  .services-box > div > p {
    font-size: 20px;
    color: #fff;
  }
  .services-box > div > p > span {
    color: coral;
  }
  .services-box > div > h1 {
    color: #fff;
    font-family: "playfair display";
    font-size: 50px;
    text-align: center;
    margin-top: -5px;
  }

  .services-page-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .services-content-first {
    flex-direction: column;
    row-gap: 30px;
  }
  .first-img {
    width: 100%;
  }
  .first-img > img {
    width: 100%;
    height: 380px;
  }

  .list-card {
    width: 313px;
  }
  .list-card > img {
    width: 313px;
  }

  .first-content {
    text-align: left;

    margin-top: -120px;
  }
  .services-title {
    line-height: 40px;
  }
  .first-content > p {
    color: #787878;
  }

  .services-content-second {
    display: flex;
    flex-direction: column;
  }
  .services-content-second > h3 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    font-family: "Dm sans", sans-serif;
    margin-bottom: 10px;
  }
  .united-state {
    text-align: left;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .united-state > h1 {
    color: #fea12a;
  }

  .united-services-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    column-gap: 30px;
    flex-wrap: wrap;
    row-gap: 90px;
  }
  .list-card {
    width: 350px;
    height: auto;
    position: relative;
  }
  .list-card > h1 {
    position: absolute;
    top: 10px;
    left: 5%;
    font-size: 30px;
    font-weight: 700;
    line-height: 0px;
  }
  .list-card > img {
    width: 350px;
    height: 300px;
    object-fit: cover;
  }

  .other-services-list > .list-card {
    width: 313px;
  }
  .other-services-list > .list-card > img {
    width: 313px;
  }

  .other-services-list {
    display: flex;
    flex-direction: column;
  }
}

.background-title {
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.1);
  font-weight: bold;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  z-index: 1;
}

.foreground-title {
  font-size: 2rem;
  color: #011e3e;
  font-weight: bold;
  position: relative;
  z-index: 2;
  margin: 0;
  margin-top: 25px;
}

.service-content .us-servicecard {
  width: 360px;
  height: 405px;
  border-radius: 0px;
  background-color: #000;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  position: relative;
}
.us-servicecard .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 23, 24, 0);
  backdrop-filter: blur(0px);
  transition: backdrop-filter 0.4s ease, background-color 0.4s ease;
  z-index: 2;
}
.service-content .us-servicecard .us-img {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 0.4s ease, filter 0.4s ease;
  z-index: 1;
  object-fit: cover;
}

.textbox {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  transition: 0.2s ease-in-out;
  z-index: 2;
}
.text {
  margin-top: -10%;
  padding-left: 10%;
  padding-right: 10%;
}

.us-servicecard:hover > .textbox {
  opacity: 1;
}

.us-servicecard:hover .overlay {
  background-color: rgba(23, 23, 24, 0.5);
  backdrop-filter: blur(2.7463px);
}

.us-servicecard:hover .us-img {
  transform: scale(1.2);
}

.us-servicecard:hover .textbox {
  opacity: 1;
}

.service-name {
  margin-top: 15px;
}

/* General Styling */

.other-services-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 80px;
  margin-top: 90px;
}

.other-services-list > .list-card > img {
  width: 355px;
  height: 300px;
  object-fit: cover;
}
.other-services-list > .list-card > h1 {
  color: #fff;
  font-family: "Londrina Outline", sans-serif;
  font-size: 50px;
}

/* Grid System */

.list-card {
  width: 355px;
  height: 385px;
  text-align: center;
  background-color: #ebebeb;
  color: #000;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.list-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* .list-card h1 {
  font-size: 9.5rem;
  margin: 10px 0 5px;
  color: #b31212;
} */

.list-card h4 {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.list-card:hover {
  transition: 0.5s;
  background-color: #fea12a;
  color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive Breakpoints */
@media (max-width: 1200px) {
  .other-services-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .section-title .background-title {
    font-size: 2.5rem;
  }

  .section-title .foreground-title {
    font-size: 1.8rem;
  }

  .other-services-list {
    gap: 15px;
  }

  .list-card h1 {
    font-size: 1.2rem;
  }

  .list-card h4 {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .section-title .background-title {
    font-size: 2rem;
  }

  .section-title .foreground-title {
    font-size: 1.5rem;
  }

  .list-card h1 {
    font-size: 1rem;
  }

  .list-card h4 {
    font-size: 0.8rem;
  }
}
