.arrow-container > button {
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fea12a;
  background-color: transparent;
  color: #fff;
  opacity: 50%;
  cursor: pointer;
  display: flex;
}
.arrow-container > button:hover {
  background-color: #fea12a;
}
.full-screen-carousel :hover {
  .arrow-container > button {
    display: flex;
  }
}
