::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #313131;
  border-radius: 5px;
}

.App {
  text-align: center;
  overflow: hidden;
}
