.slider-arrow > button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fea12a;
  background-color: transparent;
  color: #fea12a;
  cursor: pointer;
  display: none;
}
.slider-arrow > button:hover {
  background-color: #fea12a;
  color: #fff;
}
.carousel-one-container :hover {
  .slider-arrow > button {
    display: flex;
  }
}
