.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 60px;
  padding-left: 200px;
  padding-right: 190px;
}
.about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-top: 0px;
  /* background-color: #000; */
}

.about-img-cont {
  width: 590px;
  height: 390px;
  overflow: hidden;
  border-radius: 10px;
}

.about-img-cont .about-img {
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease;
}

.about-img:hover {
  transform: scale(1.45);
}

.about-content-text > p {
  font-size: 20px;
  line-height: 2;
  color: #787878;
  text-align: left;
}
.about-btn {
  display: flex;
  column-gap: 30px;
}
.cta-aboutsec {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.cta-aboutsec:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #4e4e85;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta-aboutsec span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: #ff9100;
}

.cta-aboutsec svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #ff9100;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta-aboutsec:hover:before {
  width: 100%;
  background: #4e4e85;
}

.cta-aboutsec:hover svg {
  transform: translateX(0);
}

.cta-aboutsec:active {
  transform: scale(0.95);
}

.about-data {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  text-align: left;
  width: 30%;
}

.about-data > .data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
}
.data > .side-rectangle {
  width: 7px;
  height: 80%;
  background-color: #69699e;
  padding-right: 4px;
}

.data-content > h1 {
  font-size: 24px;
}

.data-content > p {
  font-size: 16px;
  margin-top: -10px;
  color: #787878;
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .about {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 768px) {
  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .about-content {
    width: 100%;
    align-items: center;
    row-gap: 30px;
    margin-top: 0px;
  }
  .about-content-text > p {
    font-size: 16px;
    text-align: center;
  }
  .about-img {
    width: 100%;
  }
  .about-data {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
  }

  .data > .side-rectangle {
    display: none;
  }
}
