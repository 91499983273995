@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500&display=swap");

.everest-page {
  margin-top: px;
  padding-left: 0px;
  padding-right: 0px;
}

.page-header-container {
  background-color: rgb(2, 39, 150);
  border-radius: 5px;
  padding-top: 10px;
  text-align: left;
  padding-left: 30px;
  padding-bottom: 10px;
}

.page-header-title {
  font-family: "Montserrat", sans-serif;
  color: rgb(8, 143, 240);
  font-weight: 600;
  font-size: 25px;
  text-transform: uppercase;
}

.page-header-subtitle {
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  font-size: 50px;
  margin-top: -10px;
  color: white;
}
.page-header-subtitle .team {
  color: rgb(8, 143, 240);
}

.page-header-text {
  font-family: "Mulish", sans-serif;
  font-size: 23px;
  color: white;
  margin-top: -10px;
}
.everest-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 60px;
  padding-left: 200px;
  padding-right: 200px;
}

/* Style for the "Team Members" tagline */
.team-members-tagline {
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px; /* Adjust this value as needed */
  font-family: "Mulish", sans-serif;
}

.managing-director {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  justify-content: center;
  margin-bottom: -180px;
}

.managing-director-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.managing-director-content > img {
  width: 300px;
  height: 350px;
  object-fit: cover;
  border-radius: 20px;
}

.managing-director-text {
  text-align: center;
  width: 300px;
  font-family: "Roboto Slab", sans-serif;
}

.managing-director-text > h1 {
  font-size: 20px;
}

.managing-director-text > h4 {
  color: #787878;
  margin-top: -5px;
  font-size: 15px;
}

/* Remove category headers and display members directly */
.members-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 35px;
  justify-content: flex-start; /* Align to the left side */
}

.members-list-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.members-list-card > img {
  width: 250px;
  height: 295px;
  object-fit: cover;
  border-radius: 10px;
}

.members-list-card-text {
  text-align: center;
  width: 200px;
  font-family: "Roboto Slab", serif;
}

.members-list-card-text > p {
  font-size: 14px;
  margin-top: -10px;
  color: #787878;
}

.loader {
  position: relative;
  left: 35%;
  width: 350px;
  height: 180px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
}
.loading {
  width: 100%;
  height: 10px;
  background: lightgrey;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 10px;
  background: #002;
  border-radius: 10px;
  z-index: 1;
  animation: loading 0.6s alternate infinite;
}
label {
  color: #002;
  font-size: 18px;
  animation: bit 0.6s alternate infinite;
}

@keyframes bit {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    left: -25%;
  }
  100% {
    left: 70%;
  }
  0% {
    left: -25%;
  }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .everest-content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .team-members-tagline {
    margin-left: 15px; /* Adjust for medium screens */
  }

  .managing-director-content > img {
    width: 313px;
  }

  .members-list-card > img {
    width: 200px;
  }

  .members-list {
    justify-content: flex-start;
  }
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .everest-content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .team-members-tagline {
    margin-left: 10px; /* Adjust for smaller screens */
  }

  .members-list {
    justify-content: center; /* Center members list on small screens */
  }

  .managing-director-content > img {
    width: 313px;
  }

  .members-list-card > img {
    width: 200px;
  }
  .loader {
    left: 26%;
    width: 150px;
  }
}
