/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  --main-hue: 208;
  --main-color: hsl(var(--main-hue), 92%, 54%);
  --light-main-color: hsl(var(--main-hue), 91%, 55%);
  --text-color: #000;
  --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
  --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
  --input-text: #8c9aaf;
  --bg-color: #fff;
}
.contact-title-2 {
  padding-left: 200px;
  padding-right: 200px;
}
.contact-title-header {
  font-family: "Roboto Slab", serif;
  padding-top: 30px;
  color: #212158;
  text-transform: uppercase;
  font-size: 30px;
}
.contact-title-header .title-span {
  color: coral;
}
/* Contact Container */
.contact-container {
  color: #353535;
  overflow-x: hidden;
}

.contact-container .contact-content {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 90px;
}

@media (max-width: 1150px) {
  .contact-container .contact-content {
    gap: 80px;
  }
}

@media (max-width: 875px) {
  .contact-container .contact-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 50px;
  }
}

/* Left Side */
.contact-container .contact-content .left-side {
  max-width: 650px;
  width: 100%;
}

@media (max-width: 768px) {
  .contact-container .contact-content .left-side {
    gap: 10px;
  }
}

/* Map Container */
.map-container {
  width: 600px;
  height: 330px;
  background-color: transparent;
  border: 2px solid #111111;
  border-radius: 20px;
  margin-top: 95px;
}

.map-container > iframe {
  width: 600px;
  height: 330px;
  object-fit: cover;
  border-radius: 20px;
  border: 0;
}

@media (max-width: 1140px) {
  .map-container {
    width: 500px;
  }
  .map-container > iframe {
    width: 500px;
  }
}

@media only screen and (max-device-width: 768px) {
  .map-container {
    width: 400px;
    height: 400px;
  }
  .map-container > iframe {
    width: 400px;
    height: 400px;
  }
}

/* Contact Form Container */
.contact-container .contact-form-container {
  max-width: 550px;
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(33, 33, 88, 0.82);
}

.contact-container .contact-form-container h2 {
  font-size: 1.5em;
  margin: 0.5em;
}

.contact-container .contact-form-container p {
  font-size: 1.1em;
  margin: 0 0 20px 0.9em;
}

@media (max-width: 768px) {
  .contact-container .contact-form-container h2 {
    text-align: center;
  }

  .contact-container .contact-form-container p {
    text-align: center;
  }
}

/* Contact Form */
.contact-container .contact-form-container form {
  display: flex;
  flex-direction: column;
}

.contact-container .contact-form-container form .form-row {
  display: flex;
  gap: 20px;
}

@media (max-width: 768px) {
  .contact-container .contact-form-container form .form-row {
    flex-direction: column;
  }
}

.contact-container .contact-form-container form .form-row input {
  flex: 1;
}

.contact-container .contact-form-container form button {
  display: inline-block;
  margin: 10px 0 0 30px;
  background-color: #fea12a;
  color: #fff;
  padding: 6px 6px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #fea12a;
  width: 40%;
}

.contact-container .contact-form-container form button:hover {
  background-color: #fff;
  transition: background-color 0.3s ease;
  color: #fea12a;
}

@media (max-width: 768px) {
  .contact-container .contact-form-container form button {
    width: 100%;
    margin-left: 0;
  }
}

/* Input Styles */
.contact-form {
  display: grid !important;
  width: 100% !important;
  margin-top: 1.05rem !important;
  grid-template-columns: repeat(2, 1fr) !important;
  column-gap: 1rem !important;
  row-gap: 1rem !important;
}

@media (max-width: 768px) {
  .contact-form {
    grid-template-columns: 1fr !important;
  }
}

.input-wrap {
  position: relative !important;
}

.w-100 {
  grid-column: span 2 !important;
}

@media (max-width: 768px) {
  .w-100 {
    grid-column: span 1 !important;
  }
}

.contact-input {
  width: 90%;
  background-color: var(--input-bg) !important;
  border: none !important;
  outline: none !important;
  padding: 1.5rem 0.35rem calc(0.75rem - 2px) 1.35rem !important;
  font-family: inherit !important;
  border-radius: 20px !important;
  color: black !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
  border: 2px solid transparent !important;
  box-shadow: 0 0 0 0px hsla(var(--main-hue), 92%, 54%, 0.169) !important;
  transition: 0.3s !important;
}

.contact-input:hover {
  background-color: var(--input-bg-hover) !important;
}

textarea.contact-input {
  resize: none !important;
  width: 90% !important;
  min-height: 150px !important;
}

.input-wrap label {
  position: absolute !important;
  top: 50% !important;
  left: calc(1.35rem + 2px) !important;
  transform: translateY(-50%) !important;
  color: #9ca7b6 !important;
  pointer-events: none !important;
  transition: 0.25s !important;
}

textarea.contact-input ~ label {
  top: 1.2rem !important;
  transform: none !important;
}

.input-wrap.focus .contact-input {
  background-color: var(--bg-color) !important;
  border: 4px solid #fea12a !important;
  box-shadow: 0 0 0 5px hsla(var(--main-hue), 91%, 55%, 0.11) !important;
}

.input-wrap.focus label {
  color: #fea12a !important;
}

.input-wrap.focus .icons {
  color: #fea12a !important;
}

.input-wrap.not-empty label {
  font-size: 0.66rem !important;
  top: 0.75rem !important;
  transform: translateY(0) !important;
}

.input-wrap .icons {
  position: absolute !important;
  right: calc(1.95rem - 2px) !important;
  top: 9% !important;
  transform: translateY(98%) !important;
  pointer-events: none !important;
  color: var(--input-text) !important;
  font-size: 1.15rem !important;
  transition: 0.3s !important;
}

textarea.contact-input ~ .icons {
  top: 4.3rem !important;
  transform: none !important;
}

/* Responsive Adjustments */
@media (max-width: 780px) {
  .contact-container .contact-content {
    padding: -20px 0;
  }
}
