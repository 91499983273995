@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

/* Navbar Styles */
.navbar {
  width: 100%;
  height: auto;
  padding: 4px 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  transition: background-color 0.5s ease, top 0.5s;
  padding-left: 1%;
}

.navbar.dark-nav {
  height: 70px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 4px 2px 12px rgba(0, 0, 0, 1);
}

.navbar.dark-nav .navbar-left p {
  color: black;
}

.dark-nav li .navbar-name {
  color: black;
  font-weight: 500;
}

.navbar.hidden {
  top: -120px;
}

.navbar.visible {
  top: 0;
}

.navbar-left {
  margin-top: 5px;
  margin-left: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.navbar-left img {
  height: 60px;
  width: 120px;
  background-color: #fff;
}

.navbar-left p {
  margin-left: 10px;
  font-weight: 900;
  font-size: 25px;
  color: #fff;
}

.navbar-right {
  display: flex;
  align-items: center;
  margin-right: 3%;
}

.navbar-right .toggle {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.navbar-right .toggle span {
  position: absolute;
  height: 4px;
  width: 40px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;
}

.navbar-right .toggle span:nth-child(1) {
  transform: translateY(-15px);
  width: 25px;
  left: 15px;
}

.navbar-right .toggle span:nth-child(2) {
  transform: translateY(15px);
  width: 15px;
  left: 15px;
}

.navbar-right .toggle span:nth-child(3) {
  transform: translateX(0);
}

.navbar-right .toggle.active span:nth-child(1) {
  width: 40px;
  transform: translateY(0px) rotate(45deg);
  transition-delay: 0.125s;
}

.navbar-right .toggle.active span:nth-child(2) {
  width: 40px;
  transform: translateY(0px) rotate(315deg);
  transition-delay: 0.125s;
}

.navbar-right .toggle.active span:nth-child(3) {
  transform: translateX(60px);
}

.navbar-right .menu-toggle {
  display: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 24px;
  z-index: 200;
}

.navbar-right .nav-links {
  gap: 15px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-right .nav-links li {
  margin-right: 20px;
  font-size: 16px;
}

.navbar-right .nav-links a {
  text-decoration: none;
  color: #fff;
}

.navbar-right .nav-links a:hover,
.navbar-right .nav-links a.active {
  color: coral;
}

/* Responsive Styles */
@media (max-width: 1185px) {
  .navbar-right .menu-toggle {
    display: block;
  }

  .navbar-right .nav-links {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 200px;
    padding-top: 119px;
    backdrop-filter: blur(21.7463px);

    transition: right 0.5s ease;
    z-index: 150;
  }

  .navbar-right .nav-links.active {
    right: 0;
    display: flex;
  }

  .navbar-right .nav-links li {
    margin: 10px 0;
  }

  .dropdown {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
  }

  .navbar-left p {
    margin-left: 10px;
    font-weight: 900;
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .navbar-left p {
    font-size: 20px;
  }

  .navbar-left img {
    height: 50px;
  }
}
