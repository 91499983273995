@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");

.job-details-page {
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.job-details-container {
  text-align: left;
  width: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: start;
  background: rgba(0, 0, 0, 0.15);
  padding-left: 40px;
  padding-right: 40px;
  gap: 20px;
}

.job-details-box {
  background-color: white;
  height: 620px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin: 40px 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.job-title {
  font-family: "Playfair Display";
  font-size: 40px;
  color: #212158;
  margin-bottom: 20px;
}

.job-details-content {
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.application-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  width: 35%;
  margin-top: 40px;
  margin-bottom: 25px;
}

.form-title {
  font-family: "Playfair Display";
  font-size: 30px;
  color: #fea12a;
  margin-bottom: 20px;
}

.application-form input,
.application-form textarea {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e2e2e2;
  margin-bottom: 15px;
}

.application-form textarea {
  height: 100px;
}

.submit-button {
  background-color: #fea12a;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
}

.submit-button:hover {
  background-color: #ef5f34;
}

@media only screen and (max-width: 768px) {
  .job-details-container {
    display: flex;
    flex-direction: column;
    padding: 0px;

    align-items: center;
  }
  .job-details-box,
  .application-form {
    width: 75%;
  }

  .job-title {
    font-size: 30px;
  }

  .form-title {
    font-size: 25px;
  }
}
