.elbert-page {
  margin-top: 40px;
  padding-left: 200px;
  padding-right: 200px;
}

.elbert-container {
  width: 100%;
  height: 250px;
  border-radius: 20px;
}
.elbert-box {
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 250px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.elbert-title > h1 {
  color: #fff;
  font-family: "playfair display";
  font-size: 50px;
  text-align: center;
}

.elbert-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 60px;
}
.ceo {
  display: flex;
  justify-content: space-between;
}
.director-img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}
.director-img > img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
}
.director-text {
  text-align: left;
  width: 600px;
}
.director-text > h1 {
  font-size: 50px;
  text-transform: uppercase;
}
.director-text > h4 {
  color: #787878;
  margin-top: -30px;
}
.director-text > p {
  margin-top: 50px;
  letter-spacing: 0.5px;
}

.president {
  display: flex;
  justify-content: space-between;
}
