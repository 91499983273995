.slider-one {
  flex-wrap: wrap;
  position: relative;
  width: 540px;
  height: auto;
  /* overflow: hidden; */
  font-size: 11px;
}

.slider-cover-img {
  width: 540px;
  height: 400px;
  border-radius: 20px;
  object-fit: cover;
  overflow: hidden;
}

.slider-cover-img img {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  object-fit: cover;
  transition: 0.3s;
  cursor: pointer;
}

.slider-cover-img img:hover {
  transform: scale(1.3);
}
/* Modal styles */
.modal {
  display: block;
  position: fixed;
  z-index: 999;
  left: 0;
  top: -50px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.224);
}

.modal-content {
  background-color: #fff;
  margin: 4% auto;
  padding: 10px 20px 15px 20px;
  border: 1px solid #888;
  width: 1000px;
  font-family: "Montserrat";
  font-weight: 500;
  height: 650px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.close {
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.carousel-slider-one {
  position: absolute;
  top: 4%;
  width: 96%;
  height: 560px;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease;
}

.slider-arrow-symbol {
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin-left: 45px;
  top: 40%;
  width: 85%;
}

.carousel-slider-one.active {
  opacity: 1;
}
.portfolio-content-card-text {
  width: 70%;
  margin-top: 58%;
  margin-left: 10%;
}

@media only screen 
  and (min-device-width: 810px) 
  and (max-device-width: 1366px)  {
    .slider-one {
      flex-wrap: wrap;
      position: relative;
      width: 540px;
      height: auto;
      /* overflow: hidden; */
      font-size: 11px;
    }
    
    .slider-cover-img {
      width: 540px;
      height: 400px;
      border-radius: 20px;
      object-fit: cover;
      overflow: hidden;
    }
    
    .slider-cover-img img {
      width: 100%;
      height: 400px;
      border-radius: 20px;
      object-fit: cover;
      transition: 0.3s;
      cursor: pointer;
    }
    
    .slider-cover-img img:hover {
      transform: scale(1.3);
    }
    /* Modal styles */
    .modal {
      display: block;
      position: fixed;
      z-index: 999;
      left: 0;
      top: 200px;
      width: 80%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.224);
    }
    
    .modal-content {
      background-color: #fff;
      margin: 4% auto;
      padding: 10px 20px 15px 20px;
      border: 1px solid #888;
      width: 770px;
      font-family: "Montserrat";
      font-weight: 500;
      height: 800px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
    .close {
      position: absolute;
      top: 0px;
      right: 20px;
      font-size: 30px;
      font-weight: bold;
      cursor: pointer;
    }
    
    .carousel-slider-one {
      position: absolute;
      top: 4%;
      width: 96%;
      height: 560px;
    
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0;
      transition: opacity 1s ease;
    }
    
    .slider-arrow-symbol {
      position: absolute;
      display: flex;
      justify-content: space-between;
      margin-left: 45px;
      top: 40%;
      width: 85%;
    }
    
    .carousel-slider-one.active {
      opacity: 1;
    }
    .portfolio-content-card-text {
      width: 70%;
      margin-top: 80%;
      margin-left: 10%;
      font-size: 20px;
    }
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 768px) {
  .slider-one {
    width: 100%;
  }
  .slider-cover-img {
    width: 100%;
    height: 320px;
  }
  .slider-cover-img img {
    width: 100%;
    height: 100%;
  }
  .modal-content {
    width: 680px;
  }
  .carousel-slider-one {
    height: 400px;
  }
  .slider-arrow-symbol {
    top: 30%;
  }
  .carousel-one {
    padding-bottom: 90px;
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
    
    background-color: rgba(0, 0, 0, 0.224);
  }
  
  .modal-content {
   
 
    background-color: #fff;
    border: 1px solid #888;
    width: 340px;
    font-family: "Montserrat";
    font-weight: 500;
    height: 400px;
    position: relative;
    padding-top:200px ;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .close {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 60px;
    font-weight: bold;
    cursor: pointer;
    display: none;
  }
  
  .carousel-slider-one {
    position: absolute;
    top: -80px;
    width: 90%;
    height: 550px;
  
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .slider-arrow-symbol {
    position: absolute;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    top: 28%;
    width: 85%;
  }
  
  .carousel-slider-one.active {
    opacity: 1;
  }
  .portfolio-content-card-text {
    width: 70%;
  
    margin-top: 20%;
    
  }
}

