@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500&family=Poppins:wght@100;200;300;400;500&display=swap");

.life-herosection {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.life-hero-contents {
  text-align: left;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.life-title1 {
  font-family: "Montserrat", sans-serif;
  color: coral;
  margin-bottom: -20px;
}
.contact-subtitle {
  color: #fff;
}
.life-title2 {
  font-family: "Poppins", sans-serif;
  font-size: 54px;
  line-height: 56px;
  color: #212158;
  margin-top: -10px;
  margin-bottom: -20px;
}

.life-title3 {
  font-family: "Poppins", serif;
  font-weight: 400;
  font-size: 27px;
  color: #212158;
  padding-top: 10px;
  margin-top: -30px;
}

.life-title2 .span-hero {
  color: coral;
}

.life-hero-img-div {
  width: 100%;
  height: 100%;
  padding-right: 40px;
}

.life-hero-img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.life-events-section {
  background-color: #f8f8f8;
  padding: 40px 0;
}

.life-events-title {
  font-family: "Montserrat", sans-serif;
  color: #212158;
  font-size: 32px;
  text-align: center;
  margin-bottom: 30px;
}

/* ... (previous styles) */

/* Life Events Section */
.life-events-section {
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.life-events-title {
  font-family: "Montserrat", sans-serif;
  font-size: 45px;
  color: #212158;
  text-align: center;
  border-bottom: 2px solid coral;
  width: 35%;
  margin-top: -10px;
}

.life-event {
}

.life-event-title {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  color: #212158;
  text-align: center;
  /* border-bottom: 2px solid coral; */
  /* width: 35%; */
  margin-top: 20px;
}
.life-event-description {
  font-family: "Poppins", serif;
  font-size: 18px;
  color: #555;
  line-height: 1.5;
  margin-top: -30px;
  margin-bottom: 30px;
}

/* Event Image */
.life-event-image {
  width: 400px; /* Set the desired width */
  height: 300px; /* Set the desired height */
  object-fit: cover; /* Ensures the image covers the dimensions while maintaining aspect ratio */
  border-radius: 8px; /* Optional: adds rounded corners */
}

.slick-slide {
  margin-bottom: 0px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .life-herosection {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }

  .life-hero-contents {
    text-align: left;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 0px;
  }

  .life-title1 {
    font-family: "Montserrat", sans-serif;
    color: coral;
    margin-bottom: -20px;
  }

  .life-title2 {
    font-family: "Poppins", sans-serif;
    font-size: 45px;
    line-height: 50px;
    color: #212158;
    padding-top: -20px;
    margin-bottom: -30px;
  }

  .life-title3 {
    font-family: "Poppins", serif;
    font-weight: 400;
    font-size: 23px;

    color: #212158;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .life-title2 .span-hero {
    color: #ff0065;
  }

  .life-hero-img-div {
    width: 100%;
    height: 100%;
  }

  .life-hero-img {
    border-radius: 20px;
    width: 90%;
    height: 100%;
  }

  .life-events-section {
    background-color: #f8f8f8;
    padding: 40px 0;
  }

  .life-events-title {
    font-family: "Montserrat", sans-serif;
    color: #212158;
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
  }

  /* ... (previous styles) */

  /* Life Events Section */
  .life-events-section {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
  }

  .life-events-title {
    font-family: "Montserrat", sans-serif;
    font-size: 45px;
    color: #212158;
    text-align: center;

    border-bottom: 2px solid #ff0065;
    width: 35%;
    padding-top: 30px;
  }

  .life-event {
  }

  .life-event-title {
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
  }
  .life-event-description {
    font-family: "Poppins", serif;
    font-size: 18px;
    color: #555;
    line-height: 1.5;
    margin-top: -30px;
    margin-bottom: 30px;
  }

  /* Event Image */
  .life-event-image {
    width: 100%;

    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .slick-slide {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: -10px;
  }
}

@media only screen and (min-device-width: 810px) and (max-device-width: 1366px) {
  .life-herosection {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }

  .life-hero-contents {
    text-align: center;
    padding-top: 0px;
  }

  .life-title1 {
    font-family: "Montserrat", sans-serif;
    color: coral;
    margin-bottom: -20px;
  }

  .life-title2 {
    font-family: "Poppins", sans-serif;
    font-size: 47px;
    line-height: 50px;
    color: #212158;
    padding-top: -20px;
    margin-bottom: -30px;
  }

  .life-title3 {
    font-family: "Poppins", serif;
    font-weight: 400;
    font-size: 23px;

    color: #212158;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 70px;
    padding-right: 70px;
  }

  .life-title2 .span-hero {
    color: #ff0065;
  }

  .life-hero-img-div {
    width: 100%;
    height: 100%;
  }

  .life-hero-img {
    border-radius: 20px;
    width: 70%;
    height: 100%;
  }

  .life-events-section {
    background-color: #f8f8f8;
    padding: 40px 0;
  }

  .life-events-title {
    font-family: "Montserrat", sans-serif;
    color: #212158;
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
  }

  /* ... (previous styles) */

  /* Life Events Section */
  .life-events-section {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
  }

  .life-events-title {
    font-family: "Montserrat", sans-serif;
    font-size: 45px;
    color: #212158;
    text-align: center;

    border-bottom: 2px solid #ff0065;
    width: 35%;
    padding-top: 30px;
  }

  .life-event {
  }

  .life-event-title {
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
  }
  .life-event-description {
    font-family: "Poppins", serif;
    font-size: 18px;
    color: #555;
    line-height: 1.5;
    margin-top: -30px;
    margin-bottom: 30px;
  }

  /* Event Image */
  .life-event-image {
    width: 100%;

    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .slick-slide {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: -10px;
  }
}
