.container {
  overflow: hidden;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  /* display: flex; */
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup {
  width: 65rem;
  height: 600px;
  background-color: white;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.close-button {
  position: relative;
  bottom: 95%;
  left: 45%;
  color: rgb(4, 78, 112);
  cursor: pointer;
}
