
.portfolio-page {
  margin-top: 0px;

}

.portfolio {
  margin-left: 0px;
  margin-right: 0px;
}

.portfolio-container {
  width: 100%;
  height: 250px;
  
  
}
.portfolio-box {
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 250px;
  
  display: flex;
    
}
.portfolio-box > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 80px;
}
.portfolio-box > div > p {
  font-size: 20px;
  color: #fff;
}
.portfolio-box > div > p > span {
  color: coral;
}
.portfolio-box > div > h1 {
  color: #fff;
  font-family: "playfair display";
  font-size: 50px;
  text-align: left;
  margin-top: -5px;
}

.portfolio-content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 45px;
  row-gap: 100px;
  margin-top: 60px;
  padding-bottom: 60px;
  justify-content: center;
  
}
/* 
.content-card-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.content-card {
  display: flex;
  justify-content: space-between;
}
.content-card-img {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
  overflow: hidden;
}

.content-card-img img {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
  transition: 0.3s;
  cursor: pointer;
}

.content-card-img img:hover {
  transform: scale(1.3);
}
.content-card-text {
  text-align: left;
  width: 600px;
}
.content-card-text > h4 {
  color: #787878;
}
.content-card-text > p {
  letter-spacing: 0.5px;
}
.hr-line {
  width: 100%;
  height: 3px;
  background-color: #787878;
} */

.portfolio-content-card {
  display: flex;
  flex-direction: column;
  width: 540px;
  height: auto;
 
}
.portfolio-content-card-img {
  width: 540px;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  font-family: "Montserrat";
}
/* .portfolio-content-card-img > img {
  width: 540px;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
} */
.portfolio-content-card-text {
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  
  padding-right: 20px;
  
}
.portfolio-content-card-text > h4 {
  color: #787878;
 
  
}
.portfolio-content-card-text > p {
  font-size: 14px;
}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 768px) {
  
    .portfolio-page {
      margin-top: 0px;
    
    }
    
    .portfolio {
      margin-left: 0px;
      margin-right: 0px;
    }
    
    .portfolio-container {
      width: 100%;
      height: 250px;
      border-radius: 20px;
      
      
      
      
    }
    .portfolio-box {
      background: rgba(0, 0, 0, 0.55);
      width: 100%;
      height: 250px;
      border-radius: 0px;
      display: flex;
      margin-top: 0px;
        
    }
    .portfolio-box > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 80px;
    }
    .portfolio-box > div > p {
      font-size: 20px;
      color: #fff;
    }
    .portfolio-box > div > p > span {
      color: coral;
    }
    .portfolio-box > div > h1 {
      color: #fff;
      font-family: "playfair display";
      font-size: 50px;
      text-align: left;
      margin-top: -5px;
    }
    
    .portfolio-content {
      display: flex;
      flex-direction: column;
      justify-content: center; /* Align items vertically */
      align-items: center; /* Align items horizontally */
      margin-top: 60px;
      padding-bottom: 60px;
    }
    
    .portfolio-content-card {
      width: 330px;
  

      margin-top: 20px; /* Add margin for spacing between cards */
    }
    
    .portfolio-content-card-img {
      width: 100%; /* Adjust the width to fill the container */
      height: 390px; /* Let the height adjust proportionally */
      object-fit: cover;
      border-radius: 10px;
      font-family: "Montserrat";
    }
    
    .portfolio-content-card-text {
      text-align:center; /* Center text horizontally */
      margin-top: 10px; /* Add some space between image and text */
    }
    
    .portfolio-content-card-text > h4 {
      color: #787878;
       /* Adjust margin for better spacing */
    }
    
    .portfolio-content-card-text > p {
      font-size: 15px;
      margin: 5px 0; /* Adjust margin for better spacing */
    }
    
}


  
@media only screen 
  and (min-device-width: 810px) 
  and (max-device-width: 1366px)  {

.portfolio-page {
  margin-top: 0px;
}

.portfolio {
  margin-left: 0px;
  margin-right: 0px;
}

.portfolio-container {
  width: 100%;
  height: 250px;
  border-radius: 0px;
}

.portfolio-box {
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 250px;
  border-radius: 0px;
  display: flex;
}

.portfolio-box > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 80px;
}

.portfolio-box > div > p {
  font-size: 20px;
  color: #fff;
}

.portfolio-box > div > p > span {
  color: coral;
}

.portfolio-box > div > h1 {
  color: #fff;
  font-family: "playfair display";
  font-size: 50px;
  text-align: left;
  margin-top: -5px;
}

@media only screen 
  and (min-device-width: 810px) 
  and (max-device-width: 1366px)  {

    .portfolio-content {
    
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center items vertically */
      align-items: center; /* Center items horizontally */
      margin-top: 60px;
      padding-bottom: 60px;
    }

    .portfolio-content-card {
      margin: 20px; /* Add margin for spacing between cards */
    }

    .portfolio-content-card-img {
      width: 440px;
      height: 360px;
      object-fit: cover;
      border-radius: 10px;
      font-family: "Montserrat";
    }

    .portfolio-content-card-text {
      text-align: center;
      margin-top: 10px;
    }

    .portfolio-content-card-text > h4 {
      color: #787878;
      margin: 5px 0;
    }

    .portfolio-content-card-text > p {
      font-size: 14px;
      margin: 5px 0;
    }
}


  }