.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  height: 35px;
  background-color: #1f1f1f;
  padding-left: 200px;
  padding-right: 200px;
}

.header-icon {
  display: flex;
}
.header-email {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-left > a {
  display: flex;
  column-gap: 10px;
  text-decoration: none;
  font-size: 16px;
  color: #d6d6d6;
}
.header-left > a:hover {
  color: #fea12a;
}
.header-right {
  display: flex;
  align-items: center;
  margin-right: -10%;
}
.header-links {
  display: flex;
  column-gap: 40px;
}
.header-links > a {
  text-decoration: none;
  color: #d6d6d6;
}
.header-links > a:hover {
  color: #fea12a;
}



@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 768px) {
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    height: 35px;
    background-color: #1f1f1f;
    padding-left: 2px;
    padding-right: 0px;
    display: none;
  }
  
  .header-icon {
    display: flex;
  }
  .header-email {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-left > a {
    display: flex;
    column-gap: 10px;
    text-decoration: none;
    font-size: 14px;
    color: #d6d6d6;
  }
  .header-left > a:hover {
    color: #fea12a;
  }
  .header-right {
    display: flex;
    align-items: center;
    margin-right: 0%;
  }
  .header-links {
    display: flex;
    column-gap: 1px;
  }
  .header-links > a {
    text-decoration: none;
    color: #d6d6d6;
  }
  .header-links > a:hover {
    color: #fea12a;
  }
}


  
@media only screen 
  and (min-device-width: 810px) 
  and (max-device-width: 1366px) 
  {


    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      height: 35px;
      background-color: #1f1f1f;
      padding-left: 2px;
      padding-right: 0px;
      display: none;
    }
    
    .header-icon {
      display: flex;
    }
    .header-email {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .header-left > a {
      display: flex;
      column-gap: 10px;
      text-decoration: none;
      font-size: 14px;
      color: #d6d6d6;
    }
    .header-left > a:hover {
      color: #fea12a;
    }
    .header-right {
      display: flex;
      align-items: center;
      margin-right: 0%;
    }
    .header-links {
      display: flex;
      column-gap: 30px;
    }
    .header-links > a {
      text-decoration: none;
      color: #d6d6d6;
    }
    .header-links > a:hover {
      color: #fea12a;
    }


  }